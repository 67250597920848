// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-about-jsx": () => import("./../../../src/pages/about/About/About.jsx" /* webpackChunkName: "component---src-pages-about-about-about-jsx" */),
  "component---src-pages-about-component-footer-footer-jsx": () => import("./../../../src/pages/about/component/Footer/Footer.jsx" /* webpackChunkName: "component---src-pages-about-component-footer-footer-jsx" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-blog-blog-layout-js": () => import("./../../../src/pages/blog/blog-layout.js" /* webpackChunkName: "component---src-pages-blog-blog-layout-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-component-footer-footer-jsx": () => import("./../../../src/pages/contact/component/Footer/Footer.jsx" /* webpackChunkName: "component---src-pages-contact-component-footer-footer-jsx" */),
  "component---src-pages-contact-contact-contact-jsx": () => import("./../../../src/pages/contact/Contact/Contact.jsx" /* webpackChunkName: "component---src-pages-contact-contact-contact-jsx" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-works-index-js": () => import("./../../../src/pages/works/index.js" /* webpackChunkName: "component---src-pages-works-index-js" */),
  "component---src-pages-works-works-works-jsx": () => import("./../../../src/pages/works/Works/Works.jsx" /* webpackChunkName: "component---src-pages-works-works-works-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

